<template>
  <b-modal
    id="popup-products"
    ref="popup-products"
    centered="centered"
    :title="(product.id ? 'Modifier' : 'Ajouter') + ` un produit`"
    @ok="create"
  >
    <div v-if="isCreatingProduct">
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement...
        </div>
      </div>
    </div>
    <validation-observer v-else ref="formProduct">
      <b-row>
        <b-col cols="12" class="d-flex">
          <b-form-group label="Libellé *" label-for="Libellé" style="flex: 1">
            <validation-provider
              #default="{ errors }"
              name="Libellé"
              rules="required"
            >
              <b-form-input
                type="text"
                id="label"
                v-model="product.label"
                :state="errors.length > 0 ? false : null"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
                v-on:keyup.enter="create"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Prix public *"
            label-for="Prix public"
            style="flex: 1"
          >
            <validation-provider
              #default="{ errors }"
              name="Prix public"
              rules="required"
            >
              <div class="input-group">
                <b-form-input
                  type="number"
                  id="publicPrice"
                  v-model="product.publicPrice"
                  :state="errors.length > 0 ? false : null"
                  class="not-autocomplete"
                  autocomplete="nope"
                  aria-autocomplete="nope"
                  v-on:keyup.enter="create"
                  step="0.01"
                />
                <div class="input-group-append">
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Prix net *"
            label-for="Prix net"
            style="flex: 1"
          >
            <validation-provider
              #default="{ errors }"
              name="Prix net"
              rules="required"
            >
              <div class="input-group">
                <b-form-input
                  type="number"
                  id="netPrice"
                  v-model="product.netPrice"
                  :state="errors.length > 0 ? false : null"
                  class="not-autocomplete"
                  autocomplete="nope"
                  aria-autocomplete="nope"
                  v-on:keyup.enter="create"
                  step="0.01"
                />
                <div class="input-group-append">
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex">
          <b-form-group label="Unité *" label-for="Unité" style="flex: 1">
            <validation-provider
              #default="{ errors }"
              name="Unité"
              rules="required"
            >
              <b-form-input
                type="text"
                id="unit"
                v-model="product.unit"
                :state="errors.length > 0 ? false : null"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
                v-on:keyup.enter="create"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 mx-0">
        <b-button
          class="float-left"
          v-if="product.id"
          variant="outline-danger"
          @click="deleteProductLocal(product.id, product.label)"
          ><feather-icon icon="Trash2Icon"
        /></b-button>
        <b-button class="float-right" variant="primary" @click="ok()">{{
          product.id ? "Modifier" : "Ajouter"
        }}</b-button>
        <b-button
          class="float-right mr-1"
          variant="outline-primary"
          @click="cancel()"
          >Annuler</b-button
        >
      </div>
    </template>
  </b-modal>
</template>
  
  <script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      product: {
        label: "",
        price: 0,
        unit: "",
      },
    };
  },
  methods: {
    popupCreateProductEvent(id) {
      if (id > 0) {
        this.fetchProduct(id).then((res) => {
          this.product = res;
        });
      } else this.initializeForm();
    },
    initializeForm() {
      this.product = {
        label: "",
        workspaceId: this.$store.getters.workspaceSelected.id,
      };
    },
    deleteProductLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Cette action est irréversible.",
          {
            title: `Êtes-vous sûr de vouloir supprimer le produit ${label} ?`,
            size: "sm",
            okVariant: "danger",
            okTitle: "Supprimer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveProduct(id);
            this.$nextTick(() => {
              this.$bvModal.hide("popup-products");
            });
          }
        });
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formProduct.validate().then((success1) => {
        if (success1) {
          if (this.product.id) this.updateProduct(this.product);
          else this.createProduct(this.product);
          this.$nextTick(() => {
            this.$refs["popup-products"].toggle("#toggle-btn");
          });
        }
      });
    },
    ...mapActions([
      "createProduct",
      "updateProduct",
      "fetchProduct",
      "archiveProduct",
    ]),
  },
  computed: {
    ...mapGetters(["isCreatingProduct"]),
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
};
</script>

<style scoped>
.input-group:not(.bootstrap-touchspin):focus-within .form-control {
  border-color: #0096c7;
  box-shadow: none;
  background-color: #fff !important;
}
</style>